import React, {useState, useEffect} from "react";
import axios  from "axios";

// components

import CardStats from "components/Cards/CardStats.js";
import CardStats2 from "components/Cards/CardStats2";

export default function HeaderStats() {

  const [clima, setClima] = useState([])
  const [precios, setPrecios] = useState([])

  const getClima = async () => {
        
    await axios({
        method: 'get',
        url: 'https://globalmet.mx/estaciones/conditions/3/',
        headers:{"authorization": "Token ea86a649b495c8cb159b32a89099359503f8ff28" }
    }).then((response) => {
        setClima(response.data.current_observation);
    })
  };
  
  const getPrecios = async () => {
        
    await axios({
        method: 'get',
        url: 'https://agro.globalmet.mx/precios',
    }).then((response) => {
        setPrecios(response.data.productos);
    })
  };  

  useEffect(() => {
    getPrecios();  
    getClima();
      
   
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getClima();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const precios1 = precios && precios[0] && precios[0]
  const precios2 = precios && precios[2] && precios[2]

  const nombre1 = precios1 && precios1.nombre
  const nombre2 = precios2 && precios2.nombre

  const features1 = precios1 && precios1.features[0] && precios1.features[0]
  const features2 = precios2 && precios2.features[0] && precios2.features[0]

  const max_price1 = features1 && features1.max_price
  const max_price2 = features2 && features2.max_price

  const min_price1 = features1 && features1.min_price
  const min_price2 = features2 && features2.min_price

  const supply_price1 = features1 && features1.suply
  const supply_price2 = features2 && features2.suply

  const demand_price1 = features1 && features1.demand
  const demand_price2 = features2 && features2.demand
  
  window.clima = clima;
  window.precios = precios;
  window.precios1 = precios1;
  window.precios2 = precios2;
  window.features1 = features1;
  window.features2 = features2;
  window.max_price1 = max_price1;
  window.max_price2 = max_price2;
  window.min_price1 = min_price1;
  window.min_price2 = min_price2;
  window.supply_price1 = supply_price1;
  window.supply_price2 = supply_price2;
  window.demand_price1 = demand_price1;
  window.demand_price2 = demand_price2;

  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 xl:w-12/12 px-4">
                <h1 className="font-semibold text-white text-center">Condiciones Actuales</h1>
              </div>  
            </div>
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Temperatura Actual"
                  statTitle={clima.temp_c+'°C'}
                  statIconName="fas fa-temperature-high"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Velocidad del Viento"
                  statTitle={clima.wind_kph+' KM/h'}
                  statIconName="fas fa-wind"
                  statIconColor="bg-emerald-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Humedad Relativa"
                  statTitle={clima.relative_humidity}
                  statIconName="fas fa-tint"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="Radiacion Solar"
                  statTitle={clima.radiacion_jouls_accumulated+' kJ'}
                  statIconName="fas fa-sun"
                  statIconColor="bg-yellow-500"
                />
              </div>
            </div>
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 xl:w-12/12 px-4">
                <h1 className="font-semibold text-white text-center" >Precios Actuales de Frutos</h1>
              </div>  
            </div>
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
                <CardStats2
                  statSubtitle={nombre1}
                  statTitle={' Precio Maximo: $'+ max_price1 + ' USD'}
                  statTitle2={' Precio Minimo: $'+ min_price1 + ' USD'}
                  supply={'Oferta: '+supply_price1}
                  demand={'Demanda: '+demand_price1}
                  statIconName="fas fa-sync"
                  statIconColor="bg-emerald-500"
                  statIconName2="fas fa-arrow-up"
                  statIconColor2="bg-emerald-500"
                  statIconName3="fas fa-arrow-down"
                  statIconColor3="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
                <CardStats2
                  statSubtitle={nombre2}
                  statTitle={' Precio Maximo: $'+ max_price2 + ' USD'}
                  statTitle2={' Precio Minimo: $'+ min_price2 + ' USD'}
                  supply={'Oferta: '+supply_price2}
                  demand={'Demanda: '+demand_price2}
                  statIconName="fas fa-sync"
                  statIconColor="bg-emerald-500"
                  statIconName2="fas fa-arrow-up"
                  statIconColor2="bg-emerald-500"
                  statIconName3="fas fa-arrow-down"
                  statIconColor3="bg-red-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
