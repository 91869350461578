import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import axios from "axios";
import Select from "react-select";
import moment from 'moment'



export default function CardTable2({ color }) {

  const [proyecciones, setProyecciones] = useState([]);

  const getProyecciones = async () => {
      const response = await axios.post(`https://stg-agro.globalmet.mx/cropforecast/drf_get_proyecciones`, 

          {
              "alias_proyeccion": "knn1_5",
              "fecha": "2021-11-20",
              "tipo": "D",
              "n_intervalos": "6",
              "plantacion_id": ""
          },
          {
          headers: {
              "Content-type": "application/json; charset=UTF-8",
              "Accept": "application/json",
              "Authorization":"Token 191a726f3f883fc41240a31c26af9efd800e1465"
          }
      })
        setProyecciones(response.data.etapas);
  
}
  
  useEffect(() => {
      getProyecciones();
  }, [])
  
  const etapa1 = proyecciones && proyecciones[0];
  const etapa2 = proyecciones && proyecciones[1];

  const plantaciones1 = etapa1 && etapa1.plantaciones;
  const plantaciones2 = etapa2 && etapa2.plantaciones;


  window.proyecciones = proyecciones;
  window.etapa1 = etapa1;
  window.etapa2 = etapa2;
  window.plantaciones1 = plantaciones1;
  window.plantaciones2 = plantaciones2;
  
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg text-center " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                PROYECCION VS PRODUCCION
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          { typeof(etapa1) != 'undefined' && etapa1 != null ? (
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Etapa
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  
                </th>
              </tr>
            </thead>
            <tbody>              
              <tr>
              
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                      <h3
                        className={"font-semibold text-lg " +
                          (color === "light" ? "text-blueGray-700" : "text-white")}
                      >
                        {etapa1.etapa}
                      </h3>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        <thead>
                          <tr>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Plantacion</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Seccion</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Producto</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Total Proyectado</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Total Real</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Diferencia</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          
                          { typeof(plantaciones1) != 'undefined' && plantaciones1 != null ? ( 
                  
                              plantaciones1.map((plantacion1, index) =>
                          
                            <tr key={ index }>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{ plantacion1.nombre }</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{ plantacion1.seccion }</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{ plantacion1.producto_descripcion }</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"></td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"></td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"></td>
                            </tr>
                          
                            )
                            ) : null } 
                            
                        </tbody>
                      </td>
              </tr>
            </tbody>
          </table>
          ) : null }
          { typeof(etapa2) != 'undefined' && etapa2 != null ? (
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Etapa
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                
                </th>
              </tr>
            </thead>
            <tbody>              
              <tr>
              
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                      <h3
                        className={"font-semibold text-lg " +
                          (color === "light" ? "text-blueGray-700" : "text-white")}
                      >
                        {etapa2.etapa}
                      </h3>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        <thead>
                          <tr>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Plantacion</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Seccion</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Producto</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Total Proyectado</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Total Real</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Diferencia</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          
                          { typeof(plantaciones2) != 'undefined' && plantaciones2 != null ? ( 
                  
                              plantaciones2.map((plantacion2, index) =>
                          
                            <tr key={ index }>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{ plantacion2.nombre }</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{ plantacion2.seccion }</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{ plantacion2.producto_descripcion }</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"></td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"></td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"></td>
                            </tr>
                          
                            )
                            ) : null } 
                            
                        </tbody>
                      </td>
              </tr>
            </tbody>
          </table>
          ) : null }  
        </div>
      </div>
    </>
  );
}

CardTable2.defaultProps = {
  color: "light",
};

CardTable2.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
