import React, {useState, useEffect, useRef} from 'react';
//import Plot from 'react-plotly.js'
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import moment from 'moment';


export default function CardBarChart() {

  const [prodGrafica, SetProdGrafica] = useState([])

  const getGraficaProd = async () => {
      const response = await axios.get(`https://stg-agro.globalmet.mx/cropforecast/tf/D/naive/${moment().format('YYYY-MM-DD')}/4`)
      SetProdGrafica(response.data.graficas)
      console.log(prodGrafica)
  };

  useEffect(() => {
      getGraficaProd();
  }, [])


  const data = {
    labels: prodGrafica.dias,
    datasets: prodGrafica.datasets 

  };  

  const options = {
    responsive: true,
    scales: {
      yAxes: {
        stacked: true
      },
      xAxes: {
        stacked: true
      }
    }
  };
  
const datasets = prodGrafica.datasets
window.datasets = datasets

const datasetsArray = datasets && datasets.map(dataset => dataset.data)
const valor2 = datasetsArray && datasetsArray.map(dataset => dataset.reduce((x, y) => x + y));
const valorTot = valor2 && valor2.reduce((x, y) => x + y);


window.datasetsArray = datasetsArray
window.valorTot = valorTot


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
              Proximos 7 Dias
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
              Resumen de Produccion
              </h2>
            </div>
            <div className="relative w-full max-w-full flex-grow flex-1 text-right">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
              Total Produccion 
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
              {valorTot} Cajas
              </h2>
              <h5 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
              <Link to="/pronosticos">Ver Detalle Pronosticos</Link>
              </h5>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-300-px">
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}

/*  const [id, setId] = useState('7')
  const [lecturaGrafica, SetLecturaGrafica] = useState([])

  const getGrafica1 = async () => {
      const response = await axios.get('https://agro.globalmet.mx/graficas/lecturas/flores_macho/' + id)
      SetLecturaGrafica(response.data)
  };

  useEffect(() => {
    getGrafica1();
  },[])


  const graphData = {
      z: lecturaGrafica.lecturas,
      masterGraph: {
        xAxis: "X-Axis",
        yAxis: "Y-Axis",
        zAxis: "Z-Axis",
        model: ""
      }
    };

    const handleChange = (e) => {
      setId(e.target.value);
      getGrafica1();

    };
  */




/*<>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Campo El Pañuelo
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Grafica 3D
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          
          <div className="relative h-500-px">
              <InputLabel id="lote">Lote</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="Lote"
                        id="lote"
                        value={id}
                        label="Lote"
                        onChange={handleChange}
                      >    
                        <MenuItem value={'1'}>Los 90's 1A</MenuItem>
                        <MenuItem value={'2'}>Los 90's 2A</MenuItem>
                        <MenuItem value={'3'}>Los 90's 3A</MenuItem>
                        <MenuItem value={'4'}>Los 90's 4A</MenuItem>
                        <MenuItem value={'5'}>Los 90's 5A</MenuItem>
                        <MenuItem value={'6'}>Los 90's 6A</MenuItem>
                        <MenuItem value={'7'}>Los 90's 7A</MenuItem>
                      </Select>
                    </FormControl>  
                    <Plot 
                        data={[
                            {
                              type: "surface",
                              z: graphData.z
                            }
                          ]}
                          layout={{
                            responsive: true,
                            width: 400,
                            height: 300,
                            margin: {
                              l: 30,
                              r: 30,
                              b: 60,
                              t: 70,
                              pad: 4
                            },
                            annotations: [
                              {
                                text: graphData.masterGraph.model,
                                font: {
                                  size: 18,
                                  color: "#444444"
                                },
                                showarrow: false,
                                align: "center",
                                x: 0.5,
                                y: 1.1,
                                xref: "paper",
                                yref: "paper"
                              }
                            ],
                            scene: {
                              xaxis: {
                                title: graphData.masterGraph.xAxis,
                                titlefont: {
                                  family: "Courier New, monospace",
                                  size: 12,
                                  color: "#444444"
                                }
                              },
                              yaxis: {
                                title: graphData.masterGraph.yAxis,
                                titlefont: {
                                  family: "Courier New, monospace",
                                  size: 12,
                                  color: "#444444"
                                }
                              },
                              zaxis: {
                                title: graphData.masterGraph.zAxis,
                                titlefont: {
                                  family: "Courier New, monospace",
                                  size: 12,
                                  color: "#444444"
                                }
                              }
                            }
                          }}
                    />
          </div>
        </div>
      </div>
    </>*/    