import React from "react";

// components

import CardTable2 from "components/Cards/CardTable2.js";

export default function Catalogos() {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <CardTable2 />
        </div>
      </div>
    </>
  );
}
