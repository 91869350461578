import React, {useEffect, useState} from "react";
import {Line} from 'react-chartjs-2';
import axios from 'axios'

export default function CardLineChart() {

  const [lecturaGrafica, SetLecturaGrafica] = useState([])

  const getGrafica = async () => {
      const response = await axios.get('https://agro.globalmet.mx/grafica_lecturas/campos/1')
      SetLecturaGrafica(response.data)
  };

  useEffect(() => {
      getGrafica();
  }, [])
  
  
  const data={
    labels:lecturaGrafica.labels,
    datasets:lecturaGrafica.datasets
}

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Campo El Pañuelo
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">Lecturas Capturas por Dia</h2>
              <h5 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">Ultimos 7 Dias</h5>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-300-px">
            <Line data={data}/>
          </div>
        </div>
      </div>
    </>
  );
}
