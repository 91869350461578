import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import axios from "axios";
import Select from "react-select";
import moment from 'moment'


const options = [
  { value: 'D', label: 'Diario' },
  { value: 'S', label: 'Semanal' },
  { value: 'T', label: 'Temporada' }
]


export default function CardTable({ color }) {

  const [lotes, setLotes] = useState([]);
  const [lote, setLote] = useState('');
  const [fecha, setFecha] = useState('');
  const [tipo, setTipo] = useState('');
  const [model, setModel] = useState('');
  const [intervalo, setIntervalo] = useState('');
  const [datos, setDatos] = useState([]);
  const [modelo, setModelo] = useState([])
  const [pronosticos, setPronosticos] = useState([]);

  const getLotes = async () => {
    const response = await axios.get('https://stg-agro.globalmet.mx/cropforecast/listaplantaciones')
    setLotes(response.data.lotes)
  };

  const getModelo = async () => {
    const response = await axios.get('https://stg-agro.globalmet.mx/cropforecast/tiposproyecciones')
    setModelo(response.data.tiposproyecciones)
  };

  const getDatos = async () => {
    const response = await axios.get(`https://stg-agro.globalmet.mx/cropforecast/tf/${lote.value}/${tipo.value}/${model.value}/${moment(fecha).format('YYYY-MM-DD')}/${intervalo}`)
    setDatos(response.data.plantaciones);
  }


  useEffect(() => {
      getLotes();
      getModelo();
  }, [])  

  const modeloss = modelo && modelo.map(({alias, nombre}) => ({value: alias, label: nombre}))
  
  const lotess = lotes && lotes.map(({plantacion_id, nombre}) => ({value: plantacion_id, label: nombre}))

  const modelos = modeloss

  const loteS = lotess

  const datoss = datos && datos[0]

  const fechass = datoss && datoss.fechas
  


  window.lotes = lotes
  window.lote = lote
  window.tipo = tipo
  window.fecha = fecha
  window.intervalo = intervalo
  window.pronosticos = pronosticos
  window.moment = moment
  window.datos = datos
  window.datoss = datoss
  window.DayPickerInput = DayPickerInput
  window.modeloss = modeloss
  window.loteS = loteS
  window.fechass = fechass
  
  
  let today = moment();
  

  const handleClick = async () => {
    getDatos();
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                PRONOSTICOS
              </h3>
            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-700 text-sm mt-3 mb-6 font-bold uppercase">
              Ingrese todos los datos
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <span className="text-gray-700">Lote</span>
                      <Select onChange={setLote} options={loteS} />
                  </label>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <span className="text-gray-700">Tipo Proyeccion</span>
                    <Select onChange={setTipo}  options={options} />
                  </label>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <span className="text-gray-700">Modelo</span>
                    <Select onChange={setModel}  options={modelos} />
                  </label>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  <span className="text-gray-700">Fecha</span>
                  </label>
                  <DayPickerInput onDayChange={setFecha}/>  
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Numero Intervalos
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={intervalo}
                    onChange={e => setIntervalo(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <button onClick={handleClick} type="button" class="py-4 px-6  bg-lightBlue-500 hover:bg-lightBlue-700 focus:ring-lightBlue-400 focus:ring-offset-lightBlue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2">
                    Enviar
                  </button>
                </div>
              </div>                            
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

          </form>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Plantacion
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Variedad
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Inicio Temporada
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Dias Activo
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Total Cajas por Calidad
                </th>
              </tr>
            </thead>
            <tbody>              
              <tr>
              { typeof(datos) != 'undefined' && datos != null ? ( 
                  
                  datos.map((dato) =>
                <><td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                      <h3
                        className={"font-semibold text-lg " +
                          (color === "light" ? "text-blueGray-700" : "text-white")}
                      >
                        {dato.nombre}
                      </h3>
                    </td><td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        {dato.variedad}
                      </td><td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        {dato.inicio_temporada}
                      </td><td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        {today.diff(moment(dato.inicio_temporada, 'YYYY-MM-DD'), 'days')}
                      </td><td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        <thead>
                          <tr>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Fecha</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Primera</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Segunda</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Tercera</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Tolva</th>
                            <th className={"px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")}>Total Cajas</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          
                          { typeof(fechass) != 'undefined' && fechass != null ? ( 
                  
                              fechass.map((fecha, index) =>
                          <>
                            <tr key={index}>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{fecha.dia}</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{Math.ceil(fecha.seg_cal.primera)}</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{Math.ceil(fecha.seg_cal.segunda)}</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{Math.ceil(fecha.seg_cal.tercera)}</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{Math.ceil(fecha.seg_cal.tolva)}</td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">{Math.ceil(fecha.valor)}</td>
                            </tr>
                          </> 
                            )
                            ) : null }
                            
                        </tbody>
                      </td></>
                  )
                ) : null }  
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
